<template>
  <div>
    <Navbar />
    <b-overlay>
      <div
        class="container"
        style="min-height:calc(100vh - 335px);margin-top:120px"
      >
        <div class="row d-flex justify-content-center">
          <!-- <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="wc-box rounded d-flex text-center wc-box-primary p-4 mt-md-5">
                        <div class="wc-box-icon my-iconn">
                            <b-icon-file-earmark></b-icon-file-earmark>
                        </div>
                        <h4 class="font-weight-bold mb-2 wc-title d-flex align-items-center"> {{ $t('Application') }} </h4>
                    </div>
                </div> -->
          <div
            class="col-sm-12 col-md-6 col-lg-6"
            @click="$router.push({ name: 'Application' })"
          >
            <div
              style="cursor:pointer"
              class="wc-box rounded text-center wc-box-primary p-4 mt-md-5"
            >
              <div>
                <img width="300" src="@/assets/undraw/xulosa.svg" alt="" />
              </div>
              <h4 class="font-weight-bold mb-2 wc-title mt-4">
                {{ $t("RegistrationOfAthlete") }}
              </h4>
            </div>
          </div>
           <!-- <div
            class="col-sm-12 col-md-6 col-lg-6"
            @click="$router.push({ name: 'FormGroup' })"
          >
            <div
              style="cursor:pointer"
              class="wc-box rounded text-center wc-box-primary p-4 mt-md-5"
            >
              <div>
                <img width="200" src="@/assets/undraw/xulosa.svg" alt="" />
              </div>
              <h4 class="font-weight-bold mb-2 wc-title mt-4">
                {{ $t("FormGroup") }}
              </h4>
            </div>
          </div> -->
          <!-- <div
            class="col-sm-12 col-md-6 col-lg-6"
            @click="$router.push({ name: 'ChooseChild' })"
          >
            <div
              style="cursor:pointer"
              class="wc-box rounded text-center wc-box-primary p-4 mt-md-5"
            >
              <div>
                <img width="100" src="@/assets/undraw/childsearch.svg" alt="" />
              </div>
              <h4 class="font-weight-bold mb-2 wc-title mt-4">
                {{ $t("FormGroup") }}
              </h4>
            </div>
          </div> -->
          <!-- <div
            class="col-sm-12 col-md-6 col-lg-6"
            v-if="isChooseChild"
            @click="$router.push({ name: 'ChildSelectApplication' })"
          >
            <div
              style="cursor:pointer"
              class="wc-box rounded text-center wc-box-primary p-4 mt-md-5"
            >
              <div>
                <img width="120" src="@/assets/undraw/childlist.svg" alt="" />
              </div>
              <h4 class="font-weight-bold mb-2 wc-title mt-4">
                {{ $t("tanlanganbolalar") }}
              </h4>
            </div>
          </div>
          <div
            class="col-sm-12 col-md-6 col-lg-6"
            v-if="isCourtApplication"
            @click="$router.push({ name: 'CourtApplication' })"
          >
            <div
              style="cursor:pointer"
              class="wc-box rounded text-center wc-box-primary p-4 mt-md-5"
            >
              <div>
                <img width="80" src="@/assets/undraw/courtview.svg" alt="" />
              </div>
              <h4 class="font-weight-bold mb-2 wc-title mt-4">
                {{ $t("CourtApplication") }}
              </h4>
            </div>
          </div> -->
        </div>
      </div>
    </b-overlay>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
import {
  BIconFileEarmark,
  BIconFileEarmarkCheck,
  BIconPersonPlus,
  BIconPersonLinesFill,
} from "bootstrap-vue";
export default {
  components: {
    Navbar,
    Footer,
    BIconFileEarmark,
    BIconFileEarmarkCheck,
    BIconPersonPlus,
    BIconPersonLinesFill,
  },
  computed: {
    isChooseChild() {
      return localStorage.getItem("user_info")
        ? JSON.parse(localStorage.getItem("user_info")).CanSearchChild
        : "";
    },
    isCourtApplication() {
      return localStorage.getItem("user_info")
        ? JSON.parse(localStorage.getItem("user_info")).CanViewCourtApplication
        : "";
    },
  },
};
</script>

<style lang="scss">
.wc-box.rounded:hover {
  .my-iconn {
    svg {
      g {
        circle {
          fill: #fff;
        }
      }
      path {
        fill: #fff;
      }
    }
  }
}
</style>
